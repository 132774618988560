import { ReactElement } from 'react'
import { useState, useEffect } from 'react';
import axios from "axios";
import { parseJWT } from '../../utils';
import { ToastContainer, toast, Flip } from "react-toastify";
import { Results } from "./Results";
interface ThirdPageProps {
    documentClick: any
    styleRightSide: any
    openedDocument: string,
    selectedFolderID: string
    resultList: any[]
}

export const ThirdPage: React.FC<ThirdPageProps> = ({ documentClick, styleRightSide, openedDocument, selectedFolderID, resultList }) => {
    const handleDocumentClick = (document: string, folder_id: string = '', page: number = 1) => {
        documentClick(document, folder_id, page)
    }

    return (
        <div className={`chatbox md:w-full lg:w-[33.334%] lg:min-w-[28%] text-center bg-bg_body rounded-xl`} style={styleRightSide}>
            <Results handleDocumentClick={handleDocumentClick} openedDocument={openedDocument} selectedFolderID={selectedFolderID} resultList={resultList}></Results>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                limit={1}
                transition={Flip}
            />
        </div>
    )
}