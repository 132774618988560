import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./home.css";
import { Navbar } from "./Navbar";
import axios from "axios";
import { FirstPage } from "./FirstPage";
import { SecondPage } from "./SecondPage";
import { ThirdPage } from "./ThirdPage";
import { parseJWT } from "../utils";
type SomeComponentProps = RouteComponentProps;
const Home: FC<SomeComponentProps> = ({ history }) => {
  const [openedDocument, setOpenedDocument] = useState("");
  const [selectedFolderID, setSelectedFolderID] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [positionFirst, setPositionFirst] = useState({ positionFirstX: 0 });
  const [positionSecond, setPositionSecond] = useState({ positionSecondX: 0 });
  let user = localStorage.getItem("vireo_auth") ? parseJWT(localStorage.getItem("vireo_auth")) : null;
  const styleLeftSide = {
    width: window.innerWidth < 760 ? `100%` : `calc(16.666%  + ${positionSecond.positionSecondX + positionFirst.positionFirstX + "px"})`,
    height: "calc(100vh - 72px)"
  }

  const styleCenter = {
    width: window.innerWidth < 760 ? `100%` : `50%`,
    height: "calc(100vh - 72px)"
  }

  const styleRightSide = {
    width: window.innerWidth < 760 ? `100%` : `calc(33.334%  - ${positionFirst.positionFirstX + positionSecond.positionSecondX + "px"})`,
    minWidth: `28%`,
    height: "calc(100vh - 72px)"
  }

  const handleDocumentClick = (document: string, folder_id: string = '', page: number = 1) => {
    setOpenedDocument(document)
    setSelectedFolderID(folder_id)
  }

  const [resultList, setResultList] = useState<any[]>([]);
  const resultDataFetch = async () => {
    try {
      const result = await axios.post(process.env.REACT_APP_API_URL + "api/getResults", {
        user_id: user?.sub?.id,
      });
      setResultList(result.data.data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    resultDataFetch()
  }, [])

  useEffect(() => {
    setOpenedDocument("")
  }, [selectedFolderID])
  return (
    <>
      <Navbar></Navbar>
      <div className="flex md:flex-row flex-col bg-bg_body">
        <FirstPage handleDocumentClick={handleDocumentClick} styleLeftSide={styleLeftSide} openedDocument={openedDocument} setSelectedFolderID={setSelectedFolderID} setResultList={setResultList}></FirstPage>
        <SecondPage openedDocument={openedDocument} styleCenter={styleCenter} selectedFolderID={selectedFolderID} pageNumber={pageNumber}></SecondPage>
        <ThirdPage documentClick={handleDocumentClick} styleRightSide={styleRightSide} openedDocument={openedDocument} selectedFolderID={selectedFolderID} resultList={resultList}></ThirdPage>
      </div>
    </>
  );
};

export default Home;
