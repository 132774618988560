import { ReactElement } from 'react'
import { useState, useEffect } from 'react';
import { ToastContainer, toast, Flip } from "react-toastify";
import axios from "axios";
import { useHistory } from 'react-router-dom';


export const Navbar = (): ReactElement => {
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem("vireo_auth")
        history.push('/login');
    };


    return (
        <header>
            <nav className="border-gray-200 p-3 bg-navbar_color">
                <div className="flex justify-between items-center mx-auto">
                    <div className="justify-between items-center w-full lg:flex lg:w-auto lg:order-1">
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <li>
                                <a href="/" className="text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 flex items-center  flex-row text-[25px]" >
                                    {/* <svg className="h-8 w-8" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                                    </svg> */}
                                    <span className='px-1.5'>vireo</span>
                                </a>
                            </li>
                            <li className='absolute right-[10px] w-30'>
                                <button className="block text-white flex items-center flex-row" onClick={() => {
                                    logout()
                                }}>
                                    <svg className="h-8 w-8 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                limit={1}
                transition={Flip}
            />
        </header >
    )
}