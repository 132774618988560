import React, { useEffect, useState } from 'react'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { parseJWT } from '../../utils';

interface SecondPageProps {
    openedDocument: string,
    styleCenter: any
    selectedFolderID: string
    pageNumber: number
}

export const SecondPage: React.FC<SecondPageProps> = ({ openedDocument, styleCenter, selectedFolderID, pageNumber }) => {
    let user = localStorage.getItem("vireo_auth") ? parseJWT(localStorage.getItem("vireo_auth")) : null;
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [pdfURL, setPDFURL] = useState("")
    useEffect(() => {
        if (openedDocument) {
            setPDFURL(process.env.REACT_APP_API_URL + "upload/" + user?.sub?.id + "/" + selectedFolderID + "/" + openedDocument)
        }
    }, [openedDocument])
    return (
        <div className="md:w-full lg:w-[50%] text-center bg-bg_body rounded-xl relative" style={styleCenter}>
            <div style={{ height: "calc(100vh - 90px)" }}
            >
                {
                    pdfURL !== "" ? <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer fileUrl={pdfURL} plugins={[
                            defaultLayoutPluginInstance
                        ]} />
                    </Worker> : <></>
                }

            </div>
        </div>
    );
}