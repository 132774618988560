export const parseJWT = (token: any) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const getBloodPressureValues = (responseText: string): { systolic: string, diastolic: string } | null => {
    const regex = /Blood Pressure: (\d+)\/(\d+)/;
    const match = responseText.match(regex);
    if (match) {
        const [, systolic, diastolic] = match;
        return { systolic, diastolic };
    }
    return null;
};