import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Accordion } from "./Accordion";
import { parseJWT } from "../../utils";
import { ToastContainer, toast, Flip } from "react-toastify";
import axios from "axios";

interface FirstPageProps {
    handleDocumentClick: any,
    styleLeftSide: any,
    openedDocument: string
    setSelectedFolderID: any
    setResultList: any
}
export const FirstPage: React.FC<FirstPageProps> = ({ handleDocumentClick, styleLeftSide, openedDocument, setSelectedFolderID, setResultList }) => {
    const [folderList, setFolderList] = useState<any[]>([]);
    const [openedIndex, setOpenedIndex] = useState<any>(null);
    const [isOpened, setOpened] = useState(false);
    let user = localStorage.getItem("vireo_auth") ? parseJWT(localStorage.getItem("vireo_auth")) : null;
    const addData = () => {
        setFolderList([...folderList, { folder_name: 'New Folder', documents: [], isSaved: false }]);
    }

    const dataFetch = async () => {
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getFolders", {
                user_id: user?.sub?.id
            });
            if (result.data.data.length > 0) {
                setOpened(true)
                setOpenedIndex(0)
                setSelectedFolderID(result.data.data[0]?.id)
            }
            setFolderList(result.data.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        dataFetch();
    }, [])

    const deleteFolder = async (id: string) => {
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/deleteFolder", {
                id: id,
                user_id: user?.sub?.id
            });
            if (result.status === 200) {
                dataFetch();
                setResultList(result.data.resultList)
                toast.success("Folder Deleted!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
            }
        } catch (error) {

        }
    }
    const saveFolder = async (id: string, folder_name: string) => {
        try {
            let isCreating = (id === undefined ? true : false);
            if (isCreating) {
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/createFolder", {
                    folder_name: folder_name,
                    user_id: user?.sub?.id
                });
                if (result.status === 200) {
                    dataFetch();
                    toast.success("Folder Created!", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                }
            } else {
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/updateFolder", {
                    id: id,
                    folder_name: folder_name
                });
                if (result.status === 200) {
                    toast.success("Folder Updated!", {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    dataFetch();
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const dbSaveSuccess = async () => {
        dataFetch();
    }

    const collaspe = (index: any, status: any) => {
        setOpenedIndex(index);
        setOpened(status);
        if (status == true) {
            setSelectedFolderID(folderList[index]?.id)
        } else {
            setSelectedFolderID('')
        }
    }

    const documentClick = (document: string, folder_id: string) => {
        handleDocumentClick(document, folder_id);
    }

    return (
        <div className="md:w-full lg:w-[16.666%] lg:min-w-[13%] bg-main_bg rounded-xl relative" style={styleLeftSide}>
            <div className="mb-6">
                <button className="flex items-center flex-row float-right pl-[10px] pr-[10px] pt-[2px] pb-[2px] text-black rounded bg-main_color_2 active:bg-[#282b30] mr-2 text-white mt-[5px]" onClick={(e) => addData()}>
                    <svg className="h-5 w-5 mr-[3px]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                    </svg>
                    New
                </button>
                <div className="overflow-y-scroll w-full md:h-[100px] lg:h-[500px] h-[600px] scrollbar-hide">
                    <div className="container flex flex-col">
                        <div
                        >
                            {folderList.map((folder, index: any) => {
                                return folder.isFolderDeleted === 1 ? <div key={index}></div> :
                                    <div key={index}>
                                        <div>
                                            <Accordion key={index} index={index} folder={folder} saveFolder={saveFolder} dbSaveSuccess={dbSaveSuccess} collaspe={collaspe} isOpened={((openedIndex === index && isOpened === true)) ? true : false} documentClick={documentClick} openedDocument={openedDocument} deleteFolder={deleteFolder} setResultList={setResultList}></Accordion>
                                        </div>
                                    </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                limit={1}
                transition={Flip}
            />
        </div>
    )
}