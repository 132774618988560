import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getBloodPressureValues, parseJWT } from "../../utils";
import parse from 'html-react-parser';
import { LoadingBar } from "../LoadingBar";
import xmlParser from 'xml-js';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface ResultProps {
    handleDocumentClick: any,
    openedDocument: string,
    selectedFolderID: string,
    resultList: any[]
}
export const Results: React.FC<ResultProps> = ({ handleDocumentClick, openedDocument, selectedFolderID, resultList }) => {
    const ref = useRef<HTMLParagraphElement | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsExpanded(false)
    }, [openedDocument])
    const handleExpand = (response: string) => {
        let blood_pressure = getBloodPressureValues(response)
        if (blood_pressure) {
            setXML(
                `<observation archetype_node_id="openEHR-EHR-OBSERVATION.blood_pressure.v2">
                    <name>
                        <value>Blood Pressure Observation</value>
                    </name>
                    <language>
                        <terminology_id>
                            <value>ISO_639-1</value>
                        </terminology_id>
                        <code_string>en</code_string>
                    </language>
                    <encoding>
                        <terminology_id>
                            <value>UTF-8</value>
                        </terminology_id>
                        <code_string>UTF-8</code_string>
                    </encoding>
                    <subject>
                        <external_ref>
                            <id>
                                <value>Patient12345</value>
                            </id>
                            <namespace>hospital_patient_id</namespace>
                            <type>PERSON</type>
                        </external_ref>
                    </subject>
                    <data>
                        <event>
                            <time>
                                <value>2024-04-05T14:00:00Z</value>
                            </time>
                            <systolic>
                                <magnitude>${blood_pressure?.systolic}</magnitude>
                                <units>mmHg</units>
                            </systolic>
                            <diastolic>
                                <magnitude>${blood_pressure?.diastolic}</magnitude>
                                <units>mmHg</units>
                            </diastolic>
                            <position>
                                <value>sitting</value>
                            </position>
                            <device>
                                <value>manual sphygmomanometer</value>
                            </device>
                        </event>
                    </data>
                </observation>
                `
            )
        } else {
            setXML(`<Message>No values extracted from the health data</Message>`)
        }
        setIsExpanded(!isExpanded);
    };

    const [xmlData, setXML] = useState(`<observation archetype_node_id="openEHR-EHR-OBSERVATION.blood_pressure.v2">
        <name>
            <value>Blood Pressure Observation</value>
        </name>
        <language>
            <terminology_id>
                <value>ISO_639-1</value>
            </terminology_id>
            <code_string>en</code_string>
        </language>
        <encoding>
            <terminology_id>
                <value>UTF-8</value>
            </terminology_id>
            <code_string>UTF-8</code_string>
        </encoding>
        <subject>
            <external_ref>
                <id>
                    <value>Patient12345</value>
                </id>
                <namespace>hospital_patient_id</namespace>
                <type>PERSON</type>
            </external_ref>
        </subject>
        <data>
            <event>
                <time>
                    <value>2024-04-05T14:00:00Z</value>
                </time>
                <systolic>
                    <magnitude>120</magnitude>
                    <units>mmHg</units>
                </systolic>
                <diastolic>
                    <magnitude>80</magnitude>
                    <units>mmHg</units>
                </diastolic>
                <position>
                    <value>sitting</value>
                </position>
                <device>
                    <value>manual sphygmomanometer</value>
                </device>
            </event>
        </data>
    </observation>
    `);

    const documentClick = (document: any) => {
        console.log(document)
        handleDocumentClick(document, selectedFolderID, 0)
    }

    return (
        <div>
            <div>
                <div className="grid h-full">
                    <div className="h-full">
                        <div className="flex h-full flex-grow flex-col justify-between">
                            <div className="flex-grow overflow-y-scroll scrollbar-hide h-[300px] md:h-[calc(100vh-125px)] mt-[10px]" ref={ref}>
                                <ul>
                                    {resultList?.map((result, index) => {
                                        return (
                                            <span key={result.id}>
                                                <li >
                                                    {result?.folder_id === selectedFolderID &&
                                                        ((openedDocument === "" || openedDocument === result?.source) && (
                                                            <BotMessage msg={result} index={index} documentClick={documentClick} />
                                                        ))}
                                                </li>
                                                <li className="ml-[10px]">
                                                    {
                                                        openedDocument === "" ? <></> :
                                                            result?.folder_id === selectedFolderID && result?.source === openedDocument ?
                                                                <>
                                                                    <button
                                                                        className="bg-gray-200 hover:bg-gray-300 py-2 px-4 rounded focus:outline-none"
                                                                        onClick={() => {
                                                                            handleExpand(result?.response)
                                                                        }}
                                                                    >
                                                                        {isExpanded ? 'openEHR Template ⬆️' : 'openEHR Template ⬇️'}
                                                                    </button>
                                                                    {isExpanded && (
                                                                        <SyntaxHighlighter language="xml" style={darcula} className="w-[28.5vw] p-[0] mr-[1%] overflow-x-scroll">
                                                                            {xmlData}
                                                                        </SyntaxHighlighter>
                                                                    )}
                                                                </> : <></>
                                                    }

                                                </li>
                                            </span>

                                        );
                                    })}

                                    {
                                        isLoading ? (<LoadingBar></LoadingBar>) : (<></>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const BotMessage = ({ msg, index, documentClick }: { msg: any, index: any, documentClick: any }) => {
    const copyToClipBoard = (message: string) => {
        navigator.clipboard.writeText(message);
        toast.success("Copied to Clipboard!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
        });
    }

    const handleDocumentClick = (document: any) => {
        documentClick(document)
    }

    return (
        <div className="flex items-center text-left	space-x-8 p-3 text-xl text-black">
            <div className="rounded-[8px] text-xl text-base-content w-full bg-main_bg px-[15px] py-[10px]">
                {msg?.response?.length ? (
                    <>
                        <div className="flex items-center mb-[5px]">
                            <span className="font-bold">AI:&nbsp;&nbsp;</span>
                            <div className="whitespace-pre-wrap break-words">
                                {parse(msg.response.trim())}
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {msg.source !== "" ? (
                    <>
                        {<span className="font-bold">{"Source:"}</span>}
                        {<React.Fragment key={index}>
                            <span className="flex font-bold cursor-pointer underline pl-[10%]" onClick={() => handleDocumentClick(msg.source)}>
                                {' ' + msg.source}
                            </span>
                        </React.Fragment>
                        }
                    </>
                ) : null}
            </div>
            <div className="daisy-placeholder daisy-avatar ml-[6px]">
                <div className="daisy-mask daisy-mask-square w-8 bg-secondary text-3xl font-black text-accent">
                    <svg onClick={() => copyToClipBoard(msg.text.trim())} className="h-7 w-7 text-black cursor-pointer" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x="8" y="8" width="12" height="12" rx="2" />  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
                </div>
            </div>
        </div>
    );
};

export const scrollToBottom = (element: HTMLElement) => {
    element.scroll({
        behavior: "auto",
        top: element.scrollHeight,
    });
};