import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { parseJWT } from '../../utils';
import axios from "axios";

interface AccordionProps {
    index: any
    folder: any
    saveFolder: any
    dbSaveSuccess: any
    collaspe: any
    isOpened: any
    documentClick: any
    openedDocument: string
    deleteFolder: any
    setResultList: any
}

export const Accordion: React.FC<AccordionProps> = ({ index, folder, saveFolder, dbSaveSuccess, collaspe, isOpened, documentClick, openedDocument, deleteFolder, setResultList }) => {
    const [folder_name, setFolderName] = useState(folder.folder_name);
    const [isEditing, setEditing] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isUploading, setUploading] = useState(false);
    const [documents, setDocuments] = useState<any[]>(folder.documents);
    const [documentCreatingList, setDocumentCreatingList] = useState<any[]>([]);
    let user = localStorage.getItem("vireo_auth") ? parseJWT(localStorage.getItem("vireo_auth")) : null;
    useEffect(() => {
        if (folder?.documents) {
            setDocuments(folder?.documents);
        }
    }, [folder?.documents])
    const toggleAccordion = (e: any) => {
        if (folder.isSaved)
            collaspe(index, !isOpened);
    }

    useEffect(() => {
        setEditing(false);
    }, [])

    const handleSave = (e: any, id: string) => {
        e.stopPropagation()
        saveFolder(id, folder_name);
        setEditing(false);
    }

    const editFolder = (e: any) => {
        e.stopPropagation()
        setEditing(true);
        setFolderName(folder.folder_name);
    }

    const handleDeleteFolder = (e: any, id: string) => {
        e.stopPropagation();
        deleteFolder(id)
    }

    const handleFileInputChange = (event: any) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files)
        const allowedExtensions = /(\.pdf|\.docx)$/i;
        const uploaded: any = [...uploadedFiles];
        chosenFiles.some((file) => {
            let name = file.name;
            if (uploaded.findIndex((f: any) => f.name === file.name) === -1 && allowedExtensions.exec(name)) {
                uploaded.push(file);
            }
        })
        setUploadedFiles(uploaded);
    };

    const handleAddDocument = async () => {
        if (uploadedFiles.length === 0) {
            toast.error("Please select file to add.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: 0,
                toastId: "my_toast",
            });
            return;
        }

        setUploading(true);
        const data = new FormData();
        data.append("folder_id", folder.id);
        data.append('user_id', user?.sub?.id)
        for (let i = 0; i < uploadedFiles.length; i++) {
            data.append("file", uploadedFiles[i]);
        }

        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/uploadDocument", data);
            if (result.status === 200) {
                toast.success("Document Uploaded!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                setResultList(result.data.resultList)
                dbSaveSuccess();
            }
        } catch (error) {
            console.log(error)
        }

        setUploadedFiles([]);
        setUploading(false);
    }

    const handleDocumentClick = (document: string, folder_id: string) => {
        documentClick(document, folder_id);
    }

    const setNewDocumentName = (e: any, index: number) => {
        let documentList: any = [...documentCreatingList];
        documentList[index] = e.target.value;
        setDocumentCreatingList(documentList);
    }

    const saveNewDocument = async (documentName: string) => {
        let data = new FormData();
        data.append("id", folder.id);
        data.append("name", documentName);
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/createNewDocument", data);
            if (result.status === 200) {
                toast.success("Document Created!", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: 0,
                    toastId: "my_toast",
                });
                dbSaveSuccess();
                setDocumentCreatingList([]);
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="flex flex-col">
            <div className='mt-2'>
                <div>
                    <h2 onClick={(e) => toggleAccordion(e)}>
                        <div
                            className="flex items-center justify-between w-full p-2 font-medium text-left bg-main_color_1 border border-b-0 border-gray-200 bg-[#1f2937] cursor-pointer">
                            <span className="flex items-center">
                                {
                                    isOpened ?
                                        <svg className="h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                        </svg> : <svg className="h-5 w-5 text-white" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg>
                                }
                                <span className='ml-[3px] text-white'>
                                    {folder.isSaved && !isEditing ? folder.folder_name : <input className='bg-[#555] w-[98%]' value={folder_name} onChange={(e) => setFolderName(e.target.value)} autoFocus onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSave(e, folder.id)
                                        }
                                    }}></input>}
                                </span>
                            </span>
                            <div className='flex items-center justify-center'>
                                {
                                    folder.isSaved && !isEditing ?
                                        <svg onClick={(e) => editFolder(e)} className="h-5 w-5 text-white" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg> :
                                        <svg onClick={(e) => handleSave(e, folder.id)} className="h-5 w-5 text-white " viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />  <polyline points="17 21 17 13 7 13 7 21" />  <polyline points="7 3 7 8 15 8" /></svg>
                                }
                                {
                                    folder.isSaved ? <svg onClick={(e) => handleDeleteFolder(e, folder.id)} className="h-5 w-5 text-orange-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg> : <></>
                                }

                            </div>
                        </div>
                    </h2>
                </div>

                <div className={isOpened ? `flex flex-col` : `invisible h-[0px]`} >
                    <div className="p-2 font-light">
                        <div>
                            <div>
                                {
                                    documents.length > 0 ? documents.map((document: any, i: any) => {
                                        return <div key={i}>
                                            <p key={i} className={`text-black cursor-pointer document-folder_name ${openedDocument == document ? "font-semibold" : ""}`} onClick={() => handleDocumentClick(document, folder?.id)}>{document}</p>
                                        </div>

                                    }) : (
                                        <div>
                                            <p className="text-black cursor-pointer h-[1px]" >&nbsp;</p>
                                        </div>
                                    )}
                            </div>
                            {documentCreatingList.map((document: any, index: any) => {
                                return (
                                    <div key={index}>
                                        <input className="w-[90%] text-black" value={document} onChange={(e) => setNewDocumentName(e, index)} autoFocus></input>
                                        <svg onClick={() => saveNewDocument(document)} className="h-5 w-5 text-main_color_2 float-right cursor-pointer" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />  <polyline points="17 21 17 13 7 13 7 21" />  <polyline points="7 3 7 8 15 8" /></svg>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="max-w-xl mt-2">
                            <label
                                className="flex justify-center w-full h-20 mb-2 transition bg-main_bg border-2 border-gray-300 border-dashed rounded-md appearance-none hover:border-gray-400 focus:outline-none">
                                <span className="flex items-center overflow-y-scroll scrollbar-hide">
                                    {
                                        uploadedFiles.length === 0 ?
                                            <>
                                                <span className="font-medium text-gray-600">
                                                    Add Files
                                                </span>
                                                <svg onClick={(e) => console.log(e)} className="h-7 w-7 ml-1 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="17 8 12 3 7 8" />  <line x1="12" y1="3" x2="12" y2="15" /></svg>
                                            </>
                                            :
                                            <div className='flex flex-col'>
                                                {
                                                    uploadedFiles.map((file: any, index) => (
                                                        <span key={index} className="font-medium text-gray-600">
                                                            {file.name.length > 15 ? file.name.substring(0, 10) + ".." + file.name.substring(file.name.length, file.name.length - 4) : file.name}
                                                        </span>
                                                    ))
                                                }

                                            </div>
                                    }
                                </span>
                                <input type="file" name="file_upload" multiple={true} className="hidden" onChange={(e) => handleFileInputChange(e)} />
                            </label>
                            {
                                isUploading === false ? uploadedFiles.length > 0 ? (
                                    <button className="flex mb-[-10px] items-center flex-row pl-[10px] pr-[10px] pt-[2px] pb-[2px] text-white rounded bg-main_color_2 active:bg-[#282b30]" onClick={(e) => handleAddDocument()}>
                                        <svg className="h-6 w-6 mr-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />  <polyline points="9 15 12 12 15 15" />  <line x1="12" y1="12" x2="12" y2="21" /></svg>
                                        Upload file(s)
                                    </button>
                                ) : <></> : (
                                    <button disabled={true} className="flex mb-[-10px] items-center flex-row pl-[10px] pr-[10px] pt-[2px] pb-[2px] text-white rounded bg-main_color_2">
                                        <div className="flex items-center text-left">
                                            <svg aria-hidden="true" className="w-4 h-6 mr-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                        </div>
                                        Uploading...
                                    </button>

                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}